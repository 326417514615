import { useMemo } from "react";

const replaceVariable = (text, variableToReplace, fn) => {
  var result = [];
  var parts = text.split(variableToReplace);
  for (var i = 0; i < parts.length; i++) {
    var mapping = fn(parts[i], i);
    result = result.concat(mapping);
  }
  result.pop();
  return result;
};

const TextVariableParser = ({
  textWithVariable,
  variableReplacement,
  variableToReplace,
}) => {
  const text = useMemo(() => {
    return replaceVariable(textWithVariable, variableToReplace, (part, i) => {
      return [part, variableReplacement(i)];
    });
  }, [textWithVariable, variableReplacement, variableToReplace]);

  return text;
};

export default TextVariableParser;
