import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { BaseInput } from "../Input";
import withFormHandlers from "../withFormHandlers";

const RadioGroup = ({ values, renderRadio, appearance, label, ...props }) => {
  const {
    getErrors,
    isValid,
    isPristine,
    isDirty,
    name,
    value: currentValue,
    id,
    onChange,
    ...rest
  } = props;
  const errors = getErrors();

  return (
    <BaseInput
      errorAppearance="start"
      appearance={appearance}
      label={label}
      input={
        <>
          {values.map((value, index) => {
            const itemId = `${id || name}-${
              typeof value === "string" || typeof value === "number"
                ? value
                : index
            }`;
            return renderRadio({
              ...rest,
              name: name,
              id: itemId,
              key: itemId,
              value: value,
              checked: value === currentValue,
              onChange: () => onChange(value),
            });
          })}
        </>
      }
      help={props.help}
      errors={errors}
      {...props.baseInputProps}
    />
  );
};

RadioGroup.propTypes = {
  renderRadio: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};

export default withFormHandlers({
  getValueFromEvent: (value) => value,
  requiredMessage: (
    <FormattedMessage
      id="components.atoms.Form.Input.RadioGroup.requiredError"
      defaultMessage="This option is required"
    />
  ),
})(RadioGroup);
