import React from "react";
import PropTypes from "prop-types";
import Select from "../Select";
import withCountries from "./withCountries";
import CountriesQuery from "./CountriesQuery.gql";

const CountrySelect = ({
   intl,
   countries,
   noEmptyOption,
   ...props
}) => {
  const countriesWithEmptyOption = countries;

  if (!noEmptyOption) {
      countriesWithEmptyOption.unshift({
          label: '',
          value: '',
      });
  }
  return <Select {...props} options={countriesWithEmptyOption} />;
};

CountrySelect.propTypes = {
  countries: PropTypes.array.isRequired,
};

CountrySelect.defaultProps = {
    noEmptyOption: false,
}

export default withCountries({ CountriesQuery })(CountrySelect);
