import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import BaseInput from "../BaseInput";
import withFormHandlers from "../withFormHandlers";
import branch from "recompose/branch";

//  separator used for share wishlist form.
const TEXTAREA_DEFAULT_SEPARATOR = ",";

const WishlistShareTextArea = (props) => {
  const {
    getErrors,
    isValid,
    isPristine,
    isDirty,
    baseInputProps,
    toArray,
    suggestions,
    ...rest
  } = props;
  const errors = getErrors();

  const classes = classNames("textarea", {
    "textarea--invalid": errors.length,
    "textarea--valid": isValid(),
  });
  return (
    <BaseInput
      input={<textarea className={classes} {...rest} />}
      help={props.help}
      errors={errors}
      suggestions={suggestions}
      {...props.baseInputProps}
    />
  );
};

WishlistShareTextArea.defaultProps = {
  rows: 3,
  toArray: false,
};

WishlistShareTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  toArray: PropTypes.bool,
};

export default branch(
  (props) => props.toArray,
  withFormHandlers({
    getValueFromEvent: (event, { separator = TEXTAREA_DEFAULT_SEPARATOR }) => {
      return event.target.value && event.target.value.split(separator);
    },
    getPropsFromValue: (value, { separator = TEXTAREA_DEFAULT_SEPARATOR }) => {
      return { value: value ? value.join(separator) : "" };
    },
  }),
  withFormHandlers()
)(WishlistShareTextArea);
